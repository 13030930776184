import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Placement } from "react-bootstrap/esm/Overlay";
import useTranslation from "../../translation/Translations";

interface OutOfOfficeBadgeProps {
    placement?: Placement;
    language?: string;
}

const OutOfOfficeBadge = (props: OutOfOfficeBadgeProps) => {
    const placement = props.placement || "right";
    const t = useTranslation();
    return (
        <OverlayTrigger
            placement={placement}
            overlay={<Tooltip id={t("general.outOfOffice")}>{t("general.outOfOffice")}</Tooltip>}
        >
            <div className="out-of-office-badge"></div>
        </OverlayTrigger>
    );
};

export default OutOfOfficeBadge;
