import React, { useEffect } from "react";

export function usePersistedState(key: string, defaultValue: string) {
    const parseValue = localStorage.getItem(`ssp:${window.btoa(key)}`) || defaultValue;
    const [state, setState] = React.useState(() =>
        parseValue === "" ? "" : JSON.parse(parseValue)
    );

    useEffect(() => {
        localStorage.setItem(`ssp:${window.btoa(key)}`, JSON.stringify(state));
    }, [key, state]);
    return [state, setState];
}
