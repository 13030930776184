import React, { PropsWithChildren } from "react";
import { CompanyContext } from "./CompanyContext";
import { useModuleSetup } from "../api/Setup";

interface ModuleGuardProps {
    module: string;
}

const ModuleGuard = ({ module, children }: PropsWithChildren<ModuleGuardProps>) => {
    const company = React.useContext(CompanyContext);
    const { data: setup, isLoading } = useModuleSetup(company.currentCompany);

    if (!isLoading && setup) {
        switch (module) {
            case "invoices":
                if (setup.invoiceModuleEnabled) return <>{children}</>;
                break;
            case "timekeeping":
                if (setup.timekeepingModuleEnabled) return <>{children}</>;
                break;
            default:
                return null;
        }
    }

    return null;
};

export default ModuleGuard;
