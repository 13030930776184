import React from "react";
import { Button, Modal } from "react-bootstrap";
import useTranslation from "../translation/Translations";
import { useNavigate } from "react-router-dom";
import authService from "../services/Auth.service";

interface EndSessionModal {
    show: boolean;
    logout?: () => void;
    refreshWindow?: () => void;
}

const evaluateEnvToBool = (env: string | undefined) => env === "true";

const EndSessionModal = (props: EndSessionModal) => {
    const t = useTranslation();
    const navigate = useNavigate();
    const logoutOnTimeout = evaluateEnvToBool(import.meta.env.REACT_APP_LOGOUT_ON_TIMEOUT);
    return (
        <Modal show={props.show} centered backdropClassName="end-session-backdrop">
            <Modal.Header>
                <Modal.Title>{t("general.sessionExpired")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!logoutOnTimeout ? t("general.endSessionText") : t("general.endSessionLogoutText")}
            </Modal.Body>
            <Modal.Footer>
                {!logoutOnTimeout ? (
                    <Button variant="secondary" onClick={() => props.logout && props.logout()}>
                        {t("general.logout")}
                    </Button>
                ) : null}
                <Button
                    variant="primary"
                    onClick={() => {
                        if (props.refreshWindow) props.refreshWindow();
                        else navigate(`/${authService._tenant}`, { replace: true });
                    }}
                >
                    {!logoutOnTimeout ? t("general.refresh") : t("general.close")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EndSessionModal;
