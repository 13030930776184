import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Nav, Collapse } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const PortalManagementSidebarItem = () => {
    const [open, setOpen] = React.useState(false);
    return (
        <>
            <Nav.Link onClick={() => setOpen(!open)}>
                <FontAwesomeIcon icon="cog" fixedWidth className="sidebar-icon" />
                <span>Portal Management</span>
                <div className="nav-submenu--collapse-icon">
                    <FontAwesomeIcon fixedWidth icon={open ? "caret-up" : "caret-down"} />
                </div>
            </Nav.Link>
            <Collapse in={open}>
                <div>
                    <LinkContainer key="companiesAdmin" to="/companies">
                        <Nav.Item className="nav-submenu">Companies</Nav.Item>
                    </LinkContainer>
                    <LinkContainer key="Roles" to="/roles">
                        <Nav.Item className="nav-submenu">Roles</Nav.Item>
                    </LinkContainer>
                    <LinkContainer key="Classifiers" to="/modifications">
                        <Nav.Item className="nav-submenu">Classifiers</Nav.Item>
                    </LinkContainer>
                </div>
            </Collapse>
        </>
    );
};

export default PortalManagementSidebarItem;
