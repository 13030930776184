import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import ClaimGuard from "../ClaimGuard";
import ModuleGuard from "../ModuleGuard";
import ReportsMenuItem from "./ReportsMenuItem";
import {
    AcquaintanceDocumentCountBadge,
    DocumentCountBadge,
    InvoiceCountBadge,
    PayslipsCountBadge
} from "./SidebarBadge";
import React from "react";
import useTranslation from "../../translation/Translations";

const BaseMenuItems = () => {
    const t = useTranslation();
    return (
        <>
            <span className="sidebar-separator">{t("sidebar.myPortal")}</span>
            <LinkContainer to="/">
                <Nav.Link>
                    <FontAwesomeIcon icon="home" fixedWidth className="sidebar-icon" />
                    <span>{t("sidebar.dashboard")}</span>
                </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/documents">
                <Nav.Link>
                    <DocumentCountBadge />
                    <FontAwesomeIcon icon="file" fixedWidth className="sidebar-icon" />
                    <span>{t("sidebar.documents")}</span>
                </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/otherdocuments">
                <Nav.Link>
                    <AcquaintanceDocumentCountBadge />
                    <FontAwesomeIcon icon="book" fixedWidth className="sidebar-icon" />
                    <span>{t("sidebar.otherDocuments")}</span>
                </Nav.Link>
            </LinkContainer>
            <ReportsMenuItem />
            <ModuleGuard module="invoices">
                <ClaimGuard claim="manage_invoice" navigate={false}>
                    <LinkContainer to="/invoices">
                        <Nav.Link>
                            <InvoiceCountBadge />
                            <FontAwesomeIcon
                                icon="file-invoice-dollar"
                                fixedWidth
                                className="sidebar-icon"
                            />
                            <span>{t("sidebar.invoices")}</span>
                        </Nav.Link>
                    </LinkContainer>
                </ClaimGuard>
            </ModuleGuard>
            <ModuleGuard module="timekeeping">
                <LinkContainer to="/timekeeping">
                    <Nav.Link>
                        <FontAwesomeIcon icon="user-clock" fixedWidth className="sidebar-icon" />
                        <span>{t("sidebar.timekeeping")}</span>
                    </Nav.Link>
                </LinkContainer>
            </ModuleGuard>
            <LinkContainer to="/profile">
                <Nav.Link>
                    <FontAwesomeIcon icon="user" fixedWidth className="sidebar-icon" />
                    <span>{t("sidebar.profile")}</span>
                </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/payslips">
                <Nav.Link>
                    <PayslipsCountBadge />
                    <FontAwesomeIcon icon="money-check-alt" fixedWidth className="sidebar-icon" />
                    <span>{t("sidebar.payslips")}</span>
                </Nav.Link>
            </LinkContainer>
        </>
    );
};

export default BaseMenuItems;
