import React from "react";
import useTranslation from "../../translation/Translations";

interface ErrorPageProps {
    errorText: string;
    shouldNavigateHome: boolean;
}

const ErrorPage = (props: ErrorPageProps) => {
    const t = useTranslation();
    const hasTranslation = t(props.errorText) !== "undefined";

    return (
        <div className="d-flex align-items-center justify-content-center h-75">
            <div className="text-center">
                <h1 className="error-title display-1 fw-bold">
                    <span className="text-danger">Oops!</span>
                </h1>
                <p className="lead">{hasTranslation ? t(props.errorText) : props.errorText}</p>
                {props.shouldNavigateHome ? (
                    <a href="/" className="btn btn-primary">
                        Go Home
                    </a>
                ) : null}
            </div>
        </div>
    );
};

export default ErrorPage;
