import React, { useState, useRef, useEffect, useContext } from "react";
import Logo from "../images/alna_business_B_primary.svg?react";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CompanyContext } from "./CompanyContext";
import { EmployeeContext } from "./EmployeeContext";
import { Badge, Button, Container } from "react-bootstrap";
import LanguageSelect from "./LanguageSelect";
import { Link } from "react-router-dom";
import OutOfOfficeBadge from "./outOfOffice/OutOfOfficeBadge";
import useTranslation from "../translation/Translations";
import CompanyDto from "../dto/Company";
import { useIsMobile } from "../services/isMobile";
import httpService from "../services/Http.service";
import authService from "../services/Auth.service";
import { Helmet } from "react-helmet";

interface NavigationProps {
    toggleSidebar: () => void;
    companies: CompanyDto[];
    onLanguageChange: () => Promise<void>;
    setShowOutOfOffice: React.Dispatch<React.SetStateAction<boolean>>;
    setCurrentCompany: React.Dispatch<React.SetStateAction<string>>;
}

const Navigation = (props: NavigationProps) => {
    const burger = useRef<SVGSVGElement>(null);
    const employee = useContext(EmployeeContext);
    const [documentNotification, setDocumentNotification] = useState(false);
    const [fileNotification, setFileNotification] = useState(false);
    const [initials, setInitials] = useState("");
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const t = useTranslation();

    useEffect(() => {
        const splitName = authService._user?.profile.name?.split(" ");
        splitName &&
            setInitials(
                authService._user?.profile.name?.charAt(0) +
                    splitName[splitName.length - 1].charAt(0)
            );
    }, [authService._user]);

    const toggleBurger = () => {
        burger.current && burger.current.classList.toggle("active");
        props.toggleSidebar();
    };

    const getCompanyName = (id: string) => {
        if (id !== "") {
            const comp = props.companies.find((c) => c.id === id);
            if (comp) return comp.displayName;
        }
        return "";
    };
    const isMobile = useIsMobile();

    useEffect(() => {
        if (isMobile && employee.id) {
            httpService
                .get<number>(`document/needsApproval/count?employeeId=${employee.id}`)
                .then((res) => res.data)
                .then((data) => setDocumentNotification(data > 0));
            httpService
                .get<number>(`file/count?employeeId=${employee.id}&type=1`)
                .then((res) => res.data)
                .then((data) => setFileNotification(data > 0));
        }
    }, [isMobile, employee]);

    return (
        <>
            <Navbar collapseOnSelect bg="white" variant="dark" sticky="top" className="py-0 px-3">
                <Container fluid>
                    {isMobile &&
                    burger.current &&
                    !burger.current.classList.contains("active") &&
                    (fileNotification || documentNotification) ? (
                        <Badge className="navbar-badge" pill bg="danger">
                            !
                        </Badge>
                    ) : null}
                    <svg
                        ref={burger}
                        className="ham hamRotate ham1 burger"
                        viewBox="0 0 100 100"
                        width="2.25rem"
                        onClick={toggleBurger}
                    >
                        <path
                            className="line top"
                            d="m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40"
                        ></path>
                        <path className="line middle" d="m 30,50 h 40"></path>
                        <path
                            className="line bottom"
                            d="m 30,67
                h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40"
                        ></path>
                    </svg>
                    <Navbar.Brand href="/">
                        {/* <img
                    className="d-inline-block align-top"
                    alt=""
                    src={logo}
                    color="#2621bf"
                    height="24"
                    width="37"
                /> */}
                        <Logo width={37} height={24} className="d-inline-block align-top" />
                    </Navbar.Brand>

                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="ms-auto">
                            {/* <NotificationDropdown/> */}
                            {
                                // isMobile ? null :
                                <CompanyContext.Consumer>
                                    {({ currentCompany, setCurrentCompany }) => (
                                        <>
                                            <Helmet>
                                                <title>
                                                    Self Service Portal |{" "}
                                                    {getCompanyName(currentCompany)}
                                                </title>
                                            </Helmet>
                                            <NavDropdown
                                                title={
                                                    <span className="fw-bold">
                                                        <FontAwesomeIcon
                                                            icon="briefcase"
                                                            fixedWidth
                                                        />{" "}
                                                        {isMobile
                                                            ? ""
                                                            : getCompanyName(currentCompany)}
                                                    </span>
                                                }
                                                align="end"
                                                id="basic-nav-dropdown"
                                            >
                                                {props.companies.map((c) => (
                                                    <NavDropdown.Item
                                                        key={c.id}
                                                        onClick={() => setCurrentCompany(c.id)}
                                                    >
                                                        {isMobile && currentCompany === c.id ? (
                                                            <FontAwesomeIcon
                                                                icon="caret-right"
                                                                fixedWidth
                                                            />
                                                        ) : (
                                                            ""
                                                        )}{" "}
                                                        {c.displayName}
                                                    </NavDropdown.Item>
                                                ))}
                                            </NavDropdown>
                                        </>
                                    )}
                                </CompanyContext.Consumer>
                            }

                            <NavDropdown
                                id=""
                                onToggle={(isOpen) => setDropdownOpen(isOpen)}
                                className="navbar-user-dropdown"
                                title={
                                    <div className="image">
                                        {initials}
                                        {employee && employee.outOfOffice ? (
                                            <OutOfOfficeBadge
                                                placement="left"
                                                language={employee.language}
                                            />
                                        ) : null}
                                    </div>
                                }
                                align="end"
                            >
                                <Link
                                    className="dropdown-item d-flex align-items-center"
                                    to="/profile"
                                >
                                    <FontAwesomeIcon
                                        className="navbar-profile-icon"
                                        icon={["far", "user-circle"]}
                                        fixedWidth
                                    />
                                    <div>
                                        <div className="fw-bold">
                                            {authService._user?.profile.name}
                                        </div>
                                        <small>
                                            {authService._user?.profile.user_name as string}
                                        </small>
                                    </div>
                                </Link>
                                <NavDropdown.Divider />
                                {employee.active && (
                                    <LanguageSelect
                                        onLanguageChange={props.onLanguageChange}
                                        dropdownOpen={dropdownOpen}
                                    />
                                )}
                                <NavDropdown.Item as={Link} to="/about">
                                    <FontAwesomeIcon
                                        icon="cog"
                                        className="navbar-user-dropdown-icon"
                                        fixedWidth
                                    />{" "}
                                    {t("about.title")}
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item
                                    onClick={() => props.setShowOutOfOffice(true)}
                                    hidden={!employee.active}
                                >
                                    <FontAwesomeIcon
                                        className="navbar-user-dropdown-icon"
                                        icon="paper-plane"
                                        fixedWidth
                                    />{" "}
                                    {t("general.setOutOfOffice")}
                                </NavDropdown.Item>
                                {employee.active && <NavDropdown.Divider />}
                                <NavDropdown.Item
                                    onClick={() => {
                                        authService && authService.signOut();
                                    }}
                                >
                                    <FontAwesomeIcon
                                        className="navbar-user-dropdown-icon"
                                        icon="sign-out-alt"
                                        fixedWidth
                                    />{" "}
                                    {t("general.logout")}
                                </NavDropdown.Item>
                            </NavDropdown>
                            {import.meta.env.REACT_APP_THEME === "terminal" ? (
                                <div className="d-flex ps-2 py-2">
                                    <Button
                                        variant="primary"
                                        onClick={() => {
                                            authService && authService.signOut();
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            className="navbar-user-dropdown-icon"
                                            icon="sign-out-alt"
                                            fixedWidth
                                        />
                                        {t("general.logout")}
                                    </Button>
                                </div>
                            ) : null}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
};

export default Navigation;
