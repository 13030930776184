import React from "react";
import { ApplicationInsights, ITelemetryItem } from "@microsoft/applicationinsights-web";
import {
    AppInsightsContext,
    AppInsightsErrorBoundary,
    ReactPlugin
} from "@microsoft/applicationinsights-react-js";
import ErrorPage from "../components/error/ErrorPage";

const evaluateEnv = (env: string | undefined) => env && env !== "";

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        connectionString: import.meta.env.REACT_APP_APPINSIGHTS_CONNECTIONSTRING,
        extensions: [reactPlugin as any],
        extensionConfig: {},
        enableAutoRouteTracking: true,
        disableAjaxTracking: false,
        autoTrackPageVisitTime: true,
        enableCorsCorrelation: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true
    }
});

if (evaluateEnv(import.meta.env.REACT_APP_APPINSIGHTS_CONNECTIONSTRING)) {
    appInsights.loadAppInsights();
    appInsights.trackPageView();
}

export { reactPlugin, appInsights };

interface AppInsightsContextWeapperProps {
    reactPlugin: ReactPlugin;
}

export const AppInsightsContextWrapper = (
    props: React.PropsWithChildren<AppInsightsContextWeapperProps>
) => {
    if (evaluateEnv(import.meta.env.REACT_APP_APPINSIGHTS_CONNECTIONSTRING))
        return (
            <AppInsightsContext.Provider value={props.reactPlugin}>
                <AppInsightsErrorBoundary onError={() => <ErrorPage errorText="Something went wrong!" shouldNavigateHome={true}/>} appInsights={reactPlugin}>
                    <>{props.children}</>
                </AppInsightsErrorBoundary>
            </AppInsightsContext.Provider>
        );

    return <>{props.children}</>;
};
