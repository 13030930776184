import React, { PropsWithChildren, useEffect } from "react";
import authService, { AuthenticationResultStatus } from "../../services/Auth.service";
import { useNavigate } from "react-router-dom";

const Callback = (props: PropsWithChildren) => {
    const navigate = useNavigate();

    const processCallback = async () => {
        const url = window.location.href;
        const result = await authService.completeLogin(url);
        switch (result.status) {
            case AuthenticationResultStatus.Success:
                navigate((result.state as string) || "/", { replace: true, relative: "route" });
        }
    };

    useEffect(() => {
        processCallback();
    }, []);

    return <>{props.children || null}</>;
};

export default Callback;
