import { useQuery } from "react-query";

import EmployeePersonalInfoSetup from "../dto/EmployeePersonalInfoSetup";
import httpService from "../services/Http.service";

export const useEmployeePersonalInfoSetup = (companyId: string) => {
    return useQuery<EmployeePersonalInfoSetup>(
        ["employee-personal-info-setup", companyId],
        () =>
            httpService
                .get<EmployeePersonalInfoSetup>(`setup/personalInfo?companyId=${companyId}`)
                .then((res) => res.data),
        {
            enabled: companyId !== ""
        }
    );
};

export const useModuleSetup = (companyId: string) => {
    return useQuery(
        ["module-setup", companyId],
        () => httpService.get(`setup/module?companyId=${companyId}`).then((res) => res.data),
        {
            enabled: companyId !== ""
        }
    );
};
