import React, { PropsWithChildren } from "react";
import { useContext } from "react";
import { EmployeeContext } from "../components/EmployeeContext";
import EmployeeFunction from "../enum/EmployeeFunction";
import { useEmployeeFunction } from "../api/Employee";

const FunctionContext = React.createContext<EmployeeFunction>(EmployeeFunction.Employee);

const FunctionProvider = (props: PropsWithChildren) => {
    const employee = useContext(EmployeeContext);
    const { data } = useEmployeeFunction(employee.position);

    return (
        <FunctionContext.Provider value={data || EmployeeFunction.Employee}>
            {props.children}
        </FunctionContext.Provider>
    );
};

export const useFunction = () => {
    return useContext(FunctionContext);
};

interface FunctionGuardProps {
    functions: EmployeeFunction[];
}

export const FunctionGuard = (props: PropsWithChildren<FunctionGuardProps>) => {
    const func = useFunction();

    if (props.functions.includes(func)) {
        return <>{props.children}</>;
    }

    return null;
};

export default FunctionProvider;
