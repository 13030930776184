import React, { useContext } from "react";
import { EmployeeContext } from "../EmployeeContext";
import { Badge } from "react-bootstrap";
import { useDocumentForApprovalCount , useDocumentForAcquaintanceCount} from "../../api/Document";
import { useInvoiceForApprovalCount } from "../../api/Invoice";
import { usePayslipCount } from "../../api/File";

interface SidebarBadgeProps {
    value: number;
}

export const SidebarBadge = (props: SidebarBadgeProps) => {
    return !props.value || props.value === 0 ? null : (
        <Badge className="sidebar-badge" pill bg="danger">
            {props.value}
        </Badge>
    );
};

export const DocumentCountBadge = () => {
    const employee = useContext(EmployeeContext);
    const { isLoading, data } = useDocumentForApprovalCount(employee.id);

    if (isLoading) return null;

    return <SidebarBadge value={data} />;
};

export const AcquaintanceDocumentCountBadge = () => {
    const employee = useContext(EmployeeContext);
    const { isLoading, data } = useDocumentForAcquaintanceCount(employee.id);

    if (isLoading) return null;

    return <SidebarBadge value={data} />;
};

export const PayslipsCountBadge = () => {
    const employee = useContext(EmployeeContext);
    const { isLoading, data } = usePayslipCount(employee.id);

    if (isLoading) return null;

    return <SidebarBadge value={data ?? 0} />;
};

export const InvoiceCountBadge = () => {
    const employee = useContext(EmployeeContext);
    const { isLoading, data } = useInvoiceForApprovalCount(employee.id);

    if (isLoading) return null;

    return <SidebarBadge value={data ?? 0} />;
};
