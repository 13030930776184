import React from "react";
import { useToasts } from "../services/Toasts.service";
import PortalToast from "./PortalToast";

const ToastContainer = () => {
    const toasts = useToasts();

    return (
        <div className="toast-container" aria-live="polite" aria-atomic="true">
            {toasts.items?.map((i) => (
                <PortalToast key={i.id} {...i} />
            ))}
        </div>
    );
};

export default ToastContainer;
