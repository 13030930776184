import { UserManager } from "oidc-client-ts";
import React, { PropsWithChildren, useEffect } from "react";
import authService from "../../services/Auth.service";

const RedirectAuthentication = (props: PropsWithChildren) => {
    useEffect(() => {
        authService.login();
    }, []);

    return <>{props.children || null}</>;
};

export default RedirectAuthentication;
