import { useQuery, useQueryClient } from "react-query";
import EmployeeDto from "../dto/Employee";
import PositionDto from "../dto/Position";
import OrgUnitDto from "../dto/OrgUnit";
import OutOfOfficeDto from "../dto/OutOfOffice";
import HolidayRemainderDto from "../dto/HolidayRemainder";
import { placeholderEmployee, placeholderEmployeeRemainder } from "./Placeholders";
import EmployeeFunction from "../enum/EmployeeFunction";
import { Buffer } from "buffer";
import httpService from "../services/Http.service";

export const useEmployeeQuery = (userId: string, companyId: string) => {
    const queryClient = useQueryClient();

    return useQuery<EmployeeDto>(
        ["employee", userId, companyId],
        () =>
            httpService.get<EmployeeDto>(`employee/${userId}/${companyId}`).then((res) => res.data),
        {
            placeholderData: placeholderEmployee,
            onSuccess: (data) => {
                const buf = Buffer.from("language");
                localStorage.setItem(`ssp:${buf.toString("base64")}`, data.language);
                queryClient.invalidateQueries({
                    predicate: (query) => {
                        const queryKey: string = query.queryKey[0] as string;
                        return queryKey.includes("employee-");
                    }
                });
            },
            staleTime: Infinity,
            enabled: userId != null && userId !== "" && companyId !== ""
        }
    );
};

export const useEmployeePositionQuery = (employeeId: string) => {
    return useQuery<PositionDto>(
        ["employee-position", employeeId],
        () =>
            httpService
                .get<PositionDto>(`employee/position?employeeId=${employeeId}`)
                .then((res) => res.data),
        {
            enabled: employeeId !== ""
        }
    );
};

export const useEmployeeOrgUnitQuery = (employeeId: string) => {
    return useQuery<OrgUnitDto>(
        ["employee-org-unit", employeeId],
        () =>
            httpService
                .get<OrgUnitDto>(`employee/orgunit?employeeId=${employeeId}`)
                .then((res) => res.data),
        {
            enabled: employeeId !== ""
        }
    );
};

export const useEmployeeRemainder = (employeeId: string) => {
    return useQuery<HolidayRemainderDto>(
        ["employee-remainder", employeeId],
        () => httpService.get(`employee/remainder/${employeeId}`).then((res) => res.data),
        {
            placeholderData: placeholderEmployeeRemainder,
            enabled: !!employeeId && employeeId !== ""
        }
    );
};

export const useEmployeeFunction = (positionId: string) => {
    return useQuery<EmployeeFunction>(
        ["employee-function", positionId],
        () =>
            httpService
                .get<EmployeeFunction>(`employee/function?position=${positionId}`)
                .then((res) => res.data),
        {
            enabled: !!positionId && positionId !== ""
        }
    );
};

export const useOutOfOffice = (
    employeeId: string,
    isOutOfOffice: boolean,
    callback: (data: OutOfOfficeDto) => void
) => {
    return useQuery<OutOfOfficeDto>(
        ["out-of-office", employeeId, isOutOfOffice],
        () => httpService.get<OutOfOfficeDto>(`outOfOffice/${employeeId}`).then((res) => res.data),
        { enabled: employeeId !== "" && isOutOfOffice, onSuccess: callback }
    );
};
