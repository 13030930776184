import React, { useState } from "react";
import logo from "../images/alna_business_B_primary.svg";
import { Toast } from "react-bootstrap";
import ToastDto from "../dto/Toast";

const PortalToast = ({ delay = 10 * 1000, title, message, colorType }: ToastDto) => {
    const [show, setShow] = useState<boolean>(true);

    const onClose = () => {
        setShow(false);
    };

    return (
        <Toast
            show={show}
            className={`toast-bg-${colorType}`}
            onClose={onClose}
            delay={delay}
            autohide
        >
            <Toast.Header>
                <img src={logo} height="15" className="rounded me-2" alt="" />
                <strong className="me-auto">{title}</strong>
            </Toast.Header>
            <Toast.Body>{message}</Toast.Body>
        </Toast>
    );
};

const areEqual = (prevProps: ToastDto, nextProps: ToastDto) => {
    return prevProps.id === nextProps.id;
};

export default React.memo(PortalToast, areEqual);
