import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Nav, Collapse } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const UsersManagementSidebarItem = () => {
    const [open, setOpen] = React.useState(false);
    return (
        <>
            <Nav.Link onClick={() => setOpen(!open)}>
                <FontAwesomeIcon icon="cog" fixedWidth className="sidebar-icon" />
                <span>User Management</span>
                <div className="nav-submenu--collapse-icon">
                    <FontAwesomeIcon fixedWidth icon={open ? "caret-up" : "caret-down"} />
                </div>
            </Nav.Link>
            <Collapse in={open}>
                <div>
                    <LinkContainer key="usersAdmin" to="/users">
                        <Nav.Item className="nav-submenu">Users</Nav.Item>
                    </LinkContainer>
                    <LinkContainer key="employeesAdmin" to="/employees">
                        <Nav.Item className="nav-submenu">Employees</Nav.Item>
                    </LinkContainer>
                    <LinkContainer key="modificationsAdmin" to="/modifications">
                        <Nav.Item className="nav-submenu">Employee modification requests</Nav.Item>
                    </LinkContainer>
                    <LinkContainer key="substitutionsAdmin" to="/substitutions">
                        <Nav.Item className="nav-submenu">Employees substitutions</Nav.Item>
                    </LinkContainer>
                    <LinkContainer key="externalUsersAdmin" to="/external-users">
                        <Nav.Item className="nav-submenu">External Users</Nav.Item>
                    </LinkContainer>
                </div>
            </Collapse>
        </>
    );
};

export default UsersManagementSidebarItem;
