import { useQuery } from "react-query";

import ReportDefinitionDto from "../dto/ReportDefinition";
import httpService from "../services/Http.service";

export const useReportDefinitions = (companyId: string, language: string) => {
    return useQuery<ReportDefinitionDto[]>(
        ["report-definitions", companyId, language],
        () =>
            httpService
                .get<
                    ReportDefinitionDto[]
                >(`reports/definitions?companyId=${companyId}&language=${language}`)
                .then((res) => res.data),
        {
            enabled: !!companyId && !!language && companyId !== "" && language !== ""
        }
    );
};
