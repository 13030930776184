import React, { useState, useRef, SetStateAction } from "react";
import { AsyncTypeahead, Highlighter } from "react-bootstrap-typeahead";
import DocumentSubstituteDto from "../../dto/DocumentSubstitute";
import EmployeeSearchModelDto from "../../dto/EmployeeSearchModel";
import TypeaheadSearch from "../../dto/Search";
import httpService from "../../services/Http.service";

interface MultipleEmployeeSelectionProps {
    employees: DocumentSubstituteDto[];
    setEmployees: React.Dispatch<SetStateAction<DocumentSubstituteDto[]>>;
    company: string;
    filter?: (value: EmployeeSearchModelDto) => boolean;
    multiple: boolean;
    placeholder: string;
    disabled: boolean;
}

const MultipleEmployeeSelection = ({
    employees,
    setEmployees,
    company,
    filter,
    multiple,
    placeholder,
    disabled
}: MultipleEmployeeSelectionProps) => {
    const [search, setSearch] = useState<TypeaheadSearch<EmployeeSearchModelDto>>({
        isLoading: false,
        result: []
    });
    const typeahead = useRef(null);

    const handleSearch = (query: string) => {
        search && setSearch({ ...search, isLoading: true });
        httpService
            .get(`employee/search?company=${company}&q=${query}`)
            .then((res) => res.data)
            .then((data: EmployeeSearchModelDto[]) => {
                if (filter) {
                    setSearch({ result: data.filter(filter), isLoading: false });
                } else {
                    setSearch({ result: data, isLoading: false });
                }
            });
    };

    const onFocusSearch = () => {
        if (search && search.result.length === 0) handleSearch("");
    };

    return (
        <AsyncTypeahead
            id="typeahead"
            labelKey="name"
            ref={typeahead}
            allowNew={false}
            isLoading={search.isLoading}
            options={search.result}
            multiple={multiple}
            selected={employees}
            minLength={0}
            filterBy={["name", "username"]}
            onSearch={handleSearch}
            placeholder={placeholder}
            onChange={(items) => {
                if (items.length > 0) setEmployees(items);
                else setEmployees([]);
            }}
            renderMenuItemChildren={(option, props) => (
                <div key={option.id}>
                    <Highlighter search={props.text ?? ""}>{option.name}</Highlighter>
                    <br />
                    <small className="text-primary">
                        <Highlighter search={props.text ?? ""}>{option.username}</Highlighter>
                    </small>
                </div>
            )}
            inputProps={{ className: "no-validate" }}
            onFocus={() => onFocusSearch()}
            disabled={disabled}
        />
    );
};

export default MultipleEmployeeSelection;
