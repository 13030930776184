import "regenerator-runtime/runtime";
import React, { PropsWithChildren, Suspense, lazy } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { UserProvider } from "./components/authentication/UserContext";
import Callback from "./components/authentication/Callback";
import "./extensions";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faHome,
    faFile,
    faBook,
    faBriefcase,
    faCaretRight,
    faPlusSquare,
    faCheck,
    faTimes,
    faEye,
    faEdit,
    faDownload,
    faTrashAlt,
    faEllipsisV,
    faCaretDown,
    faAngleDown,
    faCommentDots,
    faPlaneDeparture,
    faSun,
    faSnowflake,
    faMoneyCheckAlt,
    faUsers,
    faPhone,
    faEnvelope,
    faFilePdf,
    faFilter,
    faArrowUp,
    faArrowDown,
    faIdCard,
    faSitemap,
    faAngleRight,
    faAngleLeft,
    faSearchPlus,
    faSearchMinus,
    faCompress,
    faLanguage,
    faSignOutAlt,
    faInfoCircle,
    faUser,
    faBell,
    faCaretUp,
    faCalculator,
    faCaretLeft,
    faFileInvoiceDollar,
    faUserTie,
    faPaperPlane,
    faFileContract,
    faUserClock,
    faEllipsisH,
    faLock,
    faUpload,
    faFileSignature,
    faSearch,
    faCog,
    faFileWord,
    faFileExcel,
    faCalendar,
    faArrowLeft,
    faArrowRight,
    faImage
} from "@fortawesome/free-solid-svg-icons";

import { far } from "@fortawesome/free-regular-svg-icons";
import EndSessionModal from "./components/EndSessionModal";
import { TranslationsProvider } from "./translation/Translations";
import SilentCallback from "./components/authentication/SilentCallback";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { FunctionGuard } from "./services/Function.service";
// const Layout = lazy(() => import("./components/Layout"));
import Layout from "./components/Layout";
const MyTeam = lazy(() => import("./components/MyTeam"));
const Timekeeping = lazy(() => import("./components/timekeeping/Timekeeping"));
const Invoice = lazy(() => import("./components/invoices/Invoice"));
const InvoiceCreatePage = lazy(() => import("./components/invoices/InvoiceCreatePage"));
const InvoiceList = lazy(() => import("./components/invoices/InvoiceList"));
const Profile = lazy(() => import("./components/profile/Profile"));
const Payslips = lazy(() => import("./components/Payslips"));
const Reports = lazy(() => import("./components/reports/Reports"));
const Document = lazy(() => import("./components/Document"));
const NewDocument = lazy(() => import("./components/documentCreation/NewDocument"));
const Documents = lazy(() => import("./components/Documents"));
const OtherDocuments = lazy(() => import("./components/OtherDocuments"));
const Dashboard = lazy(() => import("./components/Dashboard"));
import EmployeeFunction from "./enum/EmployeeFunction";
import { UserManager } from "oidc-client-ts";
const NotFoundPage = lazy(() => import("./components/NotFoundPage"));
const About = lazy(() => import("./components/about/About"));
import ClaimGuard from "./components/ClaimGuard";
import ModuleGuard from "./components/ModuleGuard";

import { AppInsightsContextWrapper, reactPlugin } from "./services/AppInsights.service";
import authService from "./services/Auth.service";
const AbsenceCalendar = lazy(() => import("./components/calendar/AbsenceCalendar"));

library.add(
    far,
    faHome,
    faFile,
    faBook,
    faBriefcase,
    faCaretRight,
    faPlusSquare,
    faCheck,
    faTimes,
    faEye,
    faEdit,
    faDownload,
    faTrashAlt,
    faEllipsisV,
    faCaretDown,
    faCaretUp,
    faAngleDown,
    faCommentDots,
    faPlaneDeparture,
    faSun,
    faSnowflake,
    faMoneyCheckAlt,
    faUsers,
    faEnvelope,
    faPhone,
    faFilePdf,
    faFilter,
    faArrowUp,
    faArrowDown,
    faIdCard,
    faSitemap,
    faAngleRight,
    faAngleLeft,
    faSearchPlus,
    faSearchMinus,
    faCompress,
    faLanguage,
    faSignOutAlt,
    faInfoCircle,
    faUser,
    faBell,
    faCalculator,
    faCaretLeft,
    faFileInvoiceDollar,
    faUserTie,
    faPaperPlane,
    faFileContract,
    faUserClock,
    faEllipsisH,
    faLock,
    faUpload,
    faFileSignature,
    faSearch,
    faCog,
    faFileWord,
    faFileExcel,
    faCalendar,
    faArrowLeft,
    faArrowRight,
    faImage
);

const WaitingSpinner = () => (
    <div className="d-flex align-items-center justify-content-center h-100 bg-dark">
        <Spinner animation="border" role="status" variant="light" />
    </div>
);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: import.meta.env.NODE_ENV === "production",
            refetchOnWindowFocus: false,
            staleTime: 10000
        }
    }
});

interface ProtectedAppProps {
    userManager?: UserManager;
    placeholder: JSX.Element;
}

const ProtectedApp = (props: PropsWithChildren<ProtectedAppProps>) => {
    return (
        <UserProvider userManager={props.userManager} placeholder={props.placeholder}>
            {props.children}
        </UserProvider>
    );
};

interface AppRouterProps {
    tenant: string;
}
const AppRouter = (props: PropsWithChildren<AppRouterProps>) => {
    if (props.tenant === "") return <BrowserRouter>{props.children}</BrowserRouter>;

    return <BrowserRouter basename={props.tenant}>{props.children}</BrowserRouter>;
};

const evaluateEnvToBool = (variable: string) => variable === "true";

const App = () => {
    const [debug] = React.useState(import.meta.env.MODE === "development");
    const language = localStorage.getItem(`ssp:${btoa("language")}`) || "ENU";

    var tenant = "";
    if (evaluateEnvToBool(import.meta.env.REACT_APP_MULTITENANT))
        tenant = window.location.pathname.getTenant();
    authService.setTenant(tenant);

    React.useEffect(() => {
        authService.startSilentRenew();
        if (import.meta.env.REACT_APP_THEME) {
            document.documentElement.className = `theme-${import.meta.env.REACT_APP_THEME}`;
        }
        return () => {
            authService.stopSilentRenew();
        };
    }, []);

    return (
        <AppRouter tenant={tenant}>
            <AppInsightsContextWrapper reactPlugin={reactPlugin}>
                <QueryClientProvider client={queryClient}>
                    <Routes>
                        <Route
                            path="/callback"
                            element={
                                <Callback>
                                    <WaitingSpinner />
                                </Callback>
                            }
                        />
                        <Route
                            path="/silent-callback"
                            element={
                                <SilentCallback>
                                    <WaitingSpinner />
                                </SilentCallback>
                            }
                        />
                        <Route
                            path="/endsession"
                            element={
                                <TranslationsProvider language={language}>
                                    <EndSessionModal show={true} />
                                </TranslationsProvider>
                            }
                        />
                        <Route
                            element={
                                <ProtectedApp
                                    userManager={authService._userManager}
                                    placeholder={<WaitingSpinner />}
                                >
                                    <Layout />
                                </ProtectedApp>
                            }
                        >
                            <Route
                                path="/"
                                element={
                                    <Suspense>
                                        <Dashboard />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/about"
                                element={
                                    <Suspense>
                                        <About />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/documents"
                                element={
                                    <Suspense>
                                        <Documents />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/otherdocuments"
                                element={
                                    <Suspense>
                                        <OtherDocuments />
                                    </Suspense>
                                }
                            />
                            {[
                                "/documents/new/",
                                "/documents/new/:type",
                                "/documents/new/:type/:cancelId"
                            ].map((p) => (
                                <Route
                                    path={p}
                                    element={
                                        <Suspense>
                                            <NewDocument />
                                        </Suspense>
                                    }
                                />
                            ))}
                            <Route
                                path="/documents/:id"
                                element={
                                    <Suspense>
                                        <Document />
                                    </Suspense>
                                }
                            />
                            {["/reports", "/reports/:code"].map((p) => (
                                <Route
                                    path={p}
                                    element={
                                        <Suspense>
                                            <Reports />
                                        </Suspense>
                                    }
                                />
                            ))}
                            <Route
                                path="/payslips"
                                element={
                                    <Suspense>
                                        <Payslips />
                                    </Suspense>
                                }
                            />
                            {["/profile", "/profile/:id"].map((p) => (
                                <Route
                                    path={p}
                                    element={
                                        <Suspense>
                                            <Profile />
                                        </Suspense>
                                    }
                                />
                            ))}
                            <Route
                                path="/invoices"
                                element={
                                    <ModuleGuard module="invoices">
                                        <ClaimGuard claim="manage_invoice" navigate={true}>
                                            <Suspense>
                                                <InvoiceList />
                                            </Suspense>
                                        </ClaimGuard>
                                    </ModuleGuard>
                                }
                            />
                            <Route
                                path="/invoices/new/"
                                element={
                                    <ModuleGuard module="invoices">
                                        <ClaimGuard claim="manage_invoice" navigate={true}>
                                            <Suspense>
                                                <InvoiceCreatePage />
                                            </Suspense>
                                        </ClaimGuard>
                                    </ModuleGuard>
                                }
                            />
                            <Route
                                path="/invoices/edit/:id"
                                element={
                                    <ModuleGuard module="invoices">
                                        <ClaimGuard claim="edit_invoices" navigate={true}>
                                            <Suspense>
                                                <InvoiceCreatePage />
                                            </Suspense>
                                        </ClaimGuard>
                                    </ModuleGuard>
                                }
                            />
                            <Route
                                path="/invoices/:id"
                                element={
                                    <ModuleGuard module="invoices">
                                        <ClaimGuard claim="manage_invoice" navigate={true}>
                                            <Suspense>
                                                <Invoice />
                                            </Suspense>
                                        </ClaimGuard>
                                    </ModuleGuard>
                                }
                            />
                            <Route
                                path="/timekeeping"
                                element={
                                    <Suspense>
                                        <Timekeeping />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/team"
                                element={
                                    <FunctionGuard
                                        functions={[EmployeeFunction.Manager, EmployeeFunction.CEO]}
                                    >
                                        <Suspense>
                                            <MyTeam />
                                        </Suspense>
                                    </FunctionGuard>
                                }
                            />
                            <Route
                                path="/schedule"
                                element={
                                    <Suspense>
                                        <AbsenceCalendar />
                                    </Suspense>
                                }
                            />
                            <Route path="/*" element={<NotFoundPage />} />
                        </Route>
                    </Routes>

                    {debug ? <ReactQueryDevtools initialIsOpen={false} /> : null}
                </QueryClientProvider>
            </AppInsightsContextWrapper>
        </AppRouter>
    );
};

export default App;
