import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import authService from "./Auth.service";

export class HttpService {
    private _client: AxiosInstance;

    constructor() {
        this._client = axios.create();

        this._client.defaults.baseURL = import.meta.env.REACT_APP_API;

        authService.subscribe(() => this.addAuthHeader());
        this.addAuthHeader();
    }

    private async addAuthHeader() {
        var token = await authService.getAccessToken();

        this._client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        if (authService._tenant !== "")
            this._client.defaults.headers.common["__tenant__"] = authService._tenant;
    }

    private handleErrors<T = any>(response: AxiosResponse<T>) {
        if (response !== null && response !== undefined) {
            if (response.headers !== null && response.headers !== undefined) {
                const contentType = response.headers["content-type"]?.toString();
                if (contentType && contentType.indexOf("application/problem+json") !== -1) {
                    throw Error(response.status.toString());
                } else {
                    throw Error(response.data as string);
                }
            }
        }
    }

    async get<T = any>(url: string, config?: AxiosRequestConfig<any>) {
        const response = await this._client
            .get<T>(url, config)
            .catch((error) => this.handleErrors(error.response));
        return response as AxiosResponse<T>;
    }

    async getBlob<T = any>(url: string, config?: AxiosRequestConfig<any>) {
        return this.get<T>(url, { ...config, responseType: "blob" });
    }

    async post<T = any, R = T>(url: string, body: T, config?: AxiosRequestConfig<any>) {
        const response = await this._client
            .post<T, AxiosResponse<R>, any>(url, body, config)
            .catch((error) => this.handleErrors(error.response));
        return response as AxiosResponse<R>;
    }

    async patch<T = any>(url: string, body?: T, config?: AxiosRequestConfig<any>) {
        const response = await this._client
            .patch(url, body, config)
            .catch((error) => this.handleErrors(error.response));
        return response;
    }

    async delete(url: string, config?: AxiosRequestConfig<any>) {
        const response = await this._client
            .delete(url, config)
            .catch((error) => this.handleErrors(error.response));
        return response as AxiosResponse;
    }

    async uploadFile(url: string, formData: FormData, config?: AxiosRequestConfig<any>) {
        const response = await this._client
            .postForm(url, formData, config)
            .catch((error) => this.handleErrors(error.response));
        return response as AxiosResponse;
    }

    static get instance() {
        return httpService;
    }
}

const httpService = new HttpService();
export default httpService;
