import React, { useState, useEffect, useContext, PropsWithChildren } from "react";
import { IdTokenClaims, Log, User, UserManager, UserManagerSettings } from "oidc-client-ts";
import RedirectAuthentication from "./RedirectAuthetication";
import authService from "../../services/Auth.service";

const UserContext: React.Context<UserContextProps | null> =
    React.createContext<UserContextProps | null>(null);

interface UserContextProps {
    placeholder: JSX.Element | null;
    user?: IdTokenClaims | null;
}

interface UserProviderProps {
    userManager?: UserManager;
    placeholder: JSX.Element | null;
}

export const useUserData = () => {
    return useContext(UserContext);
};

export const UserProvider = ({ children, placeholder }: PropsWithChildren<UserProviderProps>) => {
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const [user, setUser] = useState<IdTokenClaims | null>(null);
    const [subscription, setSubscription] = useState<number | null>();

    const populateState = async () => {
        var isAuthenticated = await authService.isAuthenticated();
        var user = await authService.getUser();
        setIsLoggedIn(isAuthenticated);
        setUser(user);
    };

    useEffect(() => {
        const currSubscription = authService.subscribe(() => populateState());
        setSubscription(() => currSubscription);
        populateState();
    }, []);

    const value = {
        user: user,
        placeholder: null
    };

    return isLoggedIn ? (
        <UserContext.Provider value={value}>{children}</UserContext.Provider>
    ) : (
        <RedirectAuthentication>{placeholder}</RedirectAuthentication>
    );
};

export const createUserManager = (config: UserManagerSettings) => {
    Log.setLogger(console);
    return new UserManager(config);
};

export const withAuthentication =
    (props: UserProviderProps) => (BaseComponent: React.ComponentType) => {
        const { userManager, placeholder } = props;
        return class WithAuthentication extends React.Component {
            render() {
                return (
                    <UserProvider userManager={userManager} placeholder={placeholder}>
                        <BaseComponent />
                    </UserProvider>
                );
            }
        };
    };
