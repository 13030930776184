import { useMutation, useQuery, useQueryClient } from "react-query";
import DocumentApprovalDto from "../dto/DocumentApproval";
import DocumentDeclineDto from "../dto/DocumentDecline";
import httpService from "../services/Http.service";

export const useInvoiceForApprovalCount = (employeeId: string) => {
    return useQuery(
        ["invoice-needsapproval-count", employeeId],
        () =>
            httpService
                .get<number>(`invoice/needsApproval/count?employeeId=${employeeId}`)
                .then((res) => res.data),
        {
            enabled: employeeId !== ""
        }
    );
};

export const useApproveInvoiceMutation = (callback: () => void) => {
    const queryClient = useQueryClient();

    return useMutation(
        (data: DocumentApprovalDto) =>
            httpService
                .patch<DocumentApprovalDto>(
                    `invoice/approve?employeeId=${data.employeeId}&invoiceId=${data.documentId}`
                )
                .then((res) => res.data),
        {
            onSuccess: () => {
                callback();
                queryClient.invalidateQueries("invoice-needsapproval-count");
            }
        }
    );
};

export const useDeclineInvoiceMutation = (callback: () => void) => {
    const queryClient = useQueryClient();

    return useMutation(
        (data: DocumentDeclineDto) =>
            httpService
                .patch<DocumentDeclineDto>(
                    `invoice/decline?employeeId=${data.employeeId}&invoiceId=${data.documentId}&reason=${data.reason}`
                )
                .then((res) => res.data),
        {
            onSuccess: () => {
                callback();
                queryClient.invalidateQueries("invoice-needsapproval-count");
            }
        }
    );
};

export const useCancelInvoiceMutation = (callback: () => void) => {
    const queryClient = useQueryClient();

    return useMutation(
        (data: DocumentDeclineDto) =>
            httpService
                .patch<DocumentDeclineDto>(
                    `invoice/cancel?employeeId=${data.employeeId}&invoiceId=${data.documentId}&reason=${data.reason}`
                )
                .then((res) => res.data),
        {
            onSuccess: () => {
                callback();
                queryClient.invalidateQueries("invoice-needsapproval-count");
            }
        }
    );
};
