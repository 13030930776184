import React from "react";
import CompanyDto from "../dto/Company";

interface CompanyContextProps {
    availableCompanies: CompanyDto[];
    currentCompany: string;
    setCurrentCompany: React.Dispatch<React.SetStateAction<string>>;
}

const defaultCompanyContextProps: CompanyContextProps = {
    availableCompanies: [],
    currentCompany: "",
    setCurrentCompany: () => {
        return undefined;
    }
};

export const CompanyContext = React.createContext(defaultCompanyContextProps);
