import React, { useRef, useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EmployeeContext } from "./EmployeeContext";
import useTranslation, { languages } from "../translation/Translations";
import httpService from "../services/Http.service";

interface LangaugeSelectProps {
    onLanguageChange: () => Promise<void>;
    dropdownOpen: boolean;
}

const LanguageSelect = ({ onLanguageChange, dropdownOpen }: LangaugeSelectProps) => {
    const options = useRef<HTMLDivElement>(null);
    const title = useRef<HTMLDivElement>(null);
    const container = useRef<HTMLDivElement>(null);
    const employee = useContext(EmployeeContext);
    const t = useTranslation();
    const allLanguages = languages;

    useEffect(() => {
        if (container.current !== null) {
            container.current.classList.remove("show-options");
        }
        if (title.current !== null) {
            title.current.scrollIntoView({ behavior: "auto" });
        }
    }, [dropdownOpen]);

    const onClickTitle = () => {
        if (container.current !== null) {
            container.current.classList.toggle("show-options");
        }
        if (options.current !== null) {
            options.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    const onClickOption = (language: string) => {
        if (container.current !== null) {
            container.current.classList.toggle("show-options");
        }
        if (title.current !== null) {
            title.current.scrollIntoView({ behavior: "smooth" });
        }
        if (employee.language !== language)
            httpService
                .patch(`employee/language?employeeId=${employee.id}&language=${language}`)
                .then(() => {
                    onLanguageChange();
                });
    };

    return (
        <div ref={container} className="language-select">
            <div ref={title} onClick={onClickTitle} className="language-select-title">
                <div>
                    <FontAwesomeIcon
                        icon="language"
                        className="navbar-user-dropdown-icon"
                        fixedWidth
                    />{" "}
                    {t("general.language")}: {t("languageDescription")}
                </div>
                <FontAwesomeIcon icon="angle-right" fixedWidth />
            </div>
            <div ref={options} className="language-select-options">
                {Object.keys(allLanguages).map((key) => (
                    <div key={`lang_${key}`} onClick={() => onClickOption(key)}>
                        {allLanguages[key]}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LanguageSelect;
