declare global {
    interface String {
        removeTenant(): string;
        getTenant(): string;
    }
}

String.prototype.removeTenant = function () {
    return this.replace(/^\/?[^\/]+/, "/");
};

String.prototype.getTenant = function () {
    return this.split("/")[1];
};

export {};
