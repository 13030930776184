import React, { PropsWithChildren, useEffect } from "react";
import authService from "../../services/Auth.service";

const SilentCallback = ({ children }: PropsWithChildren) => {
    useEffect(() => {
        // authService.completeSilentLogin(window.location.href);
        authService.completeLogin(window.location.href);
    }, []);

    return <>{children || null}</>;
};

export default SilentCallback;
