import React, { PropsWithChildren, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUserData } from "./authentication/UserContext";
interface ClaimGuardProps {
    claim: string;
    navigate: boolean;
}

const ClaimGuard = (props: PropsWithChildren<ClaimGuardProps>) => {
    const userData = useUserData();
    const navigate = useNavigate();

    if (
        userData &&
        userData.user &&
        userData?.user[props.claim] &&
        userData.user[props.claim] === "true"
    )
        return <>{props.children}</>;

    useEffect(() => {
        if (props.navigate == true) {
            navigate("/");
        }
    }, []);

    return null;
};

export default ClaimGuard;
