import { User, UserManager, UserManagerSettings, WebStorageStateStore } from "oidc-client-ts";
import userManagerConfig from "../oidc-configuration";

interface SubscriptionCallback {
    callback: () => void;
    subscription: number;
}

interface AuthenticationResult {
    status: string;
    message?: string;
    state?: any;
}

const evaluateEnvToBool = (variable: string) => variable === "true";

export class AuthorizeService {
    _userManager?: UserManager;
    _tenant: string = "";
    _user?: User;
    _isAuthenticated: boolean = false;
    _callbacks: SubscriptionCallback[] = [];
    _nextSubscriptionId: number = 0;

    async setTenant(tenant: string) {
        if (evaluateEnvToBool(import.meta.env.REACT_APP_MULTITENANT)) {
            this._tenant = tenant;
            var currentTenant = localStorage.getItem(`ssp:tenant`) ?? "";
            if (currentTenant !== "" && tenant == "") {
                window.location.href = `/${currentTenant}`;
                return;
            }

            localStorage.setItem(`ssp:tenant`, tenant);
        }
        this.ensureUserManagerInitialized();
    }

    async getUser() {
        if (this._user && this._user.profile) {
            return this._user.profile;
        }

        await this.ensureUserManagerInitialized();
        const user = await this._userManager!.getUser();
        return user && user.profile;
    }

    async getAccessToken() {
        await this.ensureUserManagerInitialized();
        const user = await this._userManager!.getUser();
        return user && user.access_token;
    }

    async isAuthenticated() {
        const user = await this.getUser();
        return !!user;
    }

    async login() {
        await this.ensureUserManagerInitialized();
        var returnUrl = window.location.pathname;

        if (evaluateEnvToBool(import.meta.env.REACT_APP_MULTITENANT)) {
            if (returnUrl.includes("/" + this._tenant))
                returnUrl = returnUrl.replace("/" + this._tenant, "");
        }
        if (returnUrl === "") returnUrl = "/";

        try {
            console.log("Trying silent signin...");
            console.log(this._userManager);
            const silentUser = await this._userManager!.signinSilent({
                state: returnUrl
            });
            this.updateState(silentUser!);
        } catch {
            this._userManager && this._userManager.signinRedirect({ state: returnUrl });
        }
    }

    async completeLogin(url: string) {
        try {
            await this.ensureUserManagerInitialized();
            const user = await this._userManager!.signinCallback(url);
            this.updateState(user!);
            return this.success(user && user.state);
        } catch (error) {
            console.log("There was an error signing in: ", error);
            return this.error("There was an error signing in.");
        }
    }

    async completeSilentLogin(url: string) {
        try {
            await this.ensureUserManagerInitialized();
            await this._userManager!.signinSilentCallback(url);
        } catch (error) {
            console.log("There was an error signing in silently: ", error);
            return this.error("There was an error signing in silently.");
        }
    }

    async signOut() {
        this.ensureUserManagerInitialized();
        localStorage.removeItem(`ssp:${btoa("company")}`);
        await this._userManager!.signoutRedirect();
    }

    async endSessionSignOut() {
        if (evaluateEnvToBool(import.meta.env.REACT_APP_MULTITENANT))
            this._userManager!.signoutRedirect({
                post_logout_redirect_uri: `${import.meta.env.REACT_APP_URL}/${
                    this._tenant
                }/endsession`
            });
        else
            this._userManager!.signoutRedirect({
                post_logout_redirect_uri: `${import.meta.env.REACT_APP_URL}/endsession`
            });
    }

    async startSilentRenew() {
        await this.ensureUserManagerInitialized();
        this._userManager?.startSilentRenew();
    }

    async stopSilentRenew() {
        await this.ensureUserManagerInitialized();
        this._userManager?.stopSilentRenew();
    }

    async ensureUserManagerInitialized() {
        if (this._userManager !== undefined) {
            return;
        }

        if (
            import.meta.env.REACT_APP_IDENTITY === undefined ||
            import.meta.env.REACT_APP_IDENTITY === ""
        )
            return;

        let requestUrl = "";
        if (!evaluateEnvToBool(import.meta.env.REACT_APP_MULTITENANT))
            requestUrl = `${import.meta.env.REACT_APP_IDENTITY}/api/configuration`;
        else requestUrl = `${import.meta.env.REACT_APP_IDENTITY}/${this._tenant}/api/configuration`;

        let response = await fetch(requestUrl);

        if (!response.ok)
            throw new Error(`Could not fetch configuration for tenant "${this._tenant}".`);

        let remoteSettings: UserManagerSettings = await response.json();
        remoteSettings.automaticSilentRenew = true;
        remoteSettings.includeIdTokenInSilentRenew = true;
        let settings = { ...userManagerConfig, ...remoteSettings };

        this._userManager = new UserManager(settings);
        this._userManager.events.addUserSignedOut(async () => {
            if (this._userManager) await this._userManager.removeUser();
            this.updateState(undefined);
        });
        this._userManager.events.addAccessTokenExpired(() => {
            // Token can expire while the app is in background
            //   -> try a silent renew in that case and otherwise redirect to home
            console.log("token expired");
            if (this._userManager)
                this._userManager.signinSilent().catch((error) => window.location.reload());
        });
        this._userManager.events.addAccessTokenExpiring(() => {
            // Token can expire while the app is in background
            //   -> try a silent renew in that case and otherwise redirect to home
            console.log("token expiring");
            // if (this._userManager)
            //     this._userManager.signinSilent().catch((error) => window.location.reload());
        });
    }

    updateState(user: User | undefined) {
        this._user = user;
        this._isAuthenticated = !!this._user;
        this.notifySubscribers();
    }

    subscribe(callback: () => void) {
        this._callbacks.push({ callback, subscription: this._nextSubscriptionId++ });
        return this._nextSubscriptionId - 1;
    }

    unsubscribe(subscriptionId: number) {
        const subscriptionIndex = this._callbacks
            .map((element, index) =>
                element.subscription === subscriptionId ? { found: true, index } : { found: false }
            )
            .filter((element) => element.found === true);

        if (subscriptionIndex.length !== 1) {
            throw new Error(`Found an invalid number of subscriptions ${subscriptionIndex.length}`);
        }

        this._callbacks.splice(subscriptionIndex[0].index!, 1);
    }

    notifySubscribers() {
        console.log("Notifying subscribers...", this._callbacks.length);
        for (let i = 0; i < this._callbacks.length; i++) {
            const callback = this._callbacks[i].callback;
            callback();
        }
    }

    error(message: string): AuthenticationResult {
        return { status: AuthenticationResultStatus.Fail, message };
    }

    success(state: any): AuthenticationResult {
        return { status: AuthenticationResultStatus.Success, state };
    }

    static get instance() {
        return authService;
    }
}

export const AuthenticationResultStatus = {
    Redirect: "redirect",
    Success: "success",
    Fail: "fail"
};

const authService = new AuthorizeService();
export default authService;
