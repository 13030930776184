import React from "react";
import PortalManagementSidebarItem from "./PortalManagementSidebarItem";
import UsersManagementSidebarItem from "./UsersManagementSidebarItem";
import ClaimGuard from "../ClaimGuard";
import useTranslation from "../../translation/Translations";

const AdministrationSidebarItems = () => {
    const t = useTranslation();
    return (
        <ClaimGuard navigate={false} claim="portal_admin">
            <span className="sidebar-separator">{t("sidebar.administration")}</span>
            <PortalManagementSidebarItem />
            <UsersManagementSidebarItem />
        </ClaimGuard>
    );
};

export default AdministrationSidebarItems;
