import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment } from "react";
import { Nav, Collapse } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useReportDefinitions } from "../../api/Report";
import React from "react";
import { EmployeeContext } from "../EmployeeContext";
import useTranslation from "../../translation/Translations";

const ReportsMenuItem = () => {
    const t = useTranslation();
    const employee = React.useContext(EmployeeContext);
    const [open, setOpen] = React.useState(true);

    const { data: reportDefinitions } = useReportDefinitions(employee.companyId, employee.language);

    if (!(reportDefinitions && reportDefinitions.length)) return null;

    return reportDefinitions && reportDefinitions.filter((r) => r.promoted).length ? (
        <Fragment>
            <Nav.Link onClick={() => setOpen(!open)}>
                <FontAwesomeIcon icon="file-contract" fixedWidth className="sidebar-icon" />
                <span>{t("sidebar.reports")}</span>
                <div className="nav-submenu--collapse-icon">
                    <FontAwesomeIcon fixedWidth icon={open ? "caret-up" : "caret-down"} />
                </div>
            </Nav.Link>
            <Collapse in={open}>
                <div>
                    <LinkContainer key="all" to="/reports">
                        <Nav.Item className="nav-submenu">{t("sidebar.allReports")}</Nav.Item>
                    </LinkContainer>
                    {reportDefinitions
                        .filter((r) => r.promoted)
                        .map((r) => (
                            <LinkContainer key={r.code} to={`/reports/${r.code}`}>
                                <Nav.Item className="nav-submenu">{r.caption}</Nav.Item>
                            </LinkContainer>
                        ))}
                </div>
            </Collapse>
        </Fragment>
    ) : (
        <LinkContainer to="/reports">
            <Nav.Link>
                <FontAwesomeIcon icon="file-contract" fixedWidth className="sidebar-icon" />
                <span>{t("sidebar.reports")}</span>
            </Nav.Link>
        </LinkContainer>
    );
};

export default ReportsMenuItem;
