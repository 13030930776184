import React, { PropsWithChildren } from "react";
import ENU from "./locales/en/translation.json";
import LTH from "./locales/lt/translation.json";
import RUS from "./locales/ru/translation.json";
import LVA from "./locales/lv/translation.json";
import util from "util";
import { lt, ru, lv, enUS } from "date-fns/locale";
// @ts-ignore
import ltDayJSTranslations from "dayjs/locale/lt";
// @ts-ignore
import ruDayJSTranslations from "dayjs/locale/ru";
// @ts-ignore
import lvDayJSTranslations from "dayjs/locale/lv";
// @ts-ignore
import enDayJSTranslations from "dayjs/locale/en-gb";
import { TranslationKey } from "../translationTYpes";

interface LanguagesProps {
    [key: string]: string;
    ENU: string;
    LTH: string;
    RUS: string;
    LVA: string;
}

interface LocalizationProps {
    [key: string]: object;
}

const localizations: LocalizationProps = {
    ENU,
    LTH,
    RUS,
    LVA
};

export const languages: LanguagesProps = {
    ENU: ENU.languageDescription,
    LTH: LTH.languageDescription,
    RUS: RUS.languageDescription,
    LVA: LVA.languageDescription
};

interface TranslationProviderProps {
    language: string;
}

export type TranslationContextType = (key: TranslationKey, ...args: string[]) => string;

const defaultTranslate = (key: TranslationKey, ...args: string[]) =>
    util.format(resolve(localizations["ENU"], key), ...args);

const TranslationsContext: React.Context<TranslationContextType> =
    React.createContext<TranslationContextType>(defaultTranslate);

const resolve = (obj: any, path: string) => {
    const splitPath: string[] = path.split(".");
    let current = obj;
    while (splitPath.length) {
        if (typeof current !== "object") {
            return undefined;
        }

        const shiftedPath = splitPath.shift();
        if (shiftedPath) {
            current = current[shiftedPath];
        }
    }
    return current;
};

export const GetLanguageCodes = (language: string) => {
    if (!language) return ENU.languageCodes;

    if (LTH.languageCodes.includes(language.toUpperCase())) return LTH.languageCodes;
    if (RUS.languageCodes.includes(language.toUpperCase())) return RUS.languageCodes;
    if (LVA.languageCodes.includes(language.toUpperCase())) return LVA.languageCodes;
    return ENU.languageCodes;
};

export const GetLocale = (language: string) => {
    const languageCodes = GetLanguageCodes(language);

    if (languageCodes.includes("LTH")) return lt;
    if (languageCodes.includes("RUS")) return ru;
    if (languageCodes.includes("LVA")) return lv;
    return enUS;
};

export const getSection = (language: string, section: string) => {
    const languageCodes = GetLanguageCodes(language);
    const translationLanguageCode = languageCodes[0];

    return resolve(localizations[translationLanguageCode], section);
};

export const getDayJsLocale = (language: string) => {
    const languageCodes = GetLanguageCodes(language);

    if (languageCodes.includes("LTH")) return ltDayJSTranslations;
    if (languageCodes.includes("RUS")) return ruDayJSTranslations;
    if (languageCodes.includes("LVA")) return lvDayJSTranslations;
    return enDayJSTranslations;
};

export const TranslationsProvider = (props: PropsWithChildren<TranslationProviderProps>) => {
    const { children, language } = props;
    const languageCodes = GetLanguageCodes(language);
    const translationLanguageCode = languageCodes[0];

    const translate = (key: TranslationKey, ...args: string[]) =>
        util.format(resolve(localizations[translationLanguageCode], key), ...args);

    return (
        <TranslationsContext.Provider value={translate}>{children}</TranslationsContext.Provider>
    );
};

const useTranslation = () => React.useContext(TranslationsContext);

export default useTranslation;
