const configuration = {
    authority: import.meta.env.REACT_APP_IDENTITY,
    client_id: "js",
    redirect_uri: `${import.meta.env.REACT_APP_URL}/callback`,
    silent_redirect_uri: `${import.meta.env.REACT_APP_URL}/silent-callback`,
    response_type: "code",
    scope: "openid profile ssp.api",
    post_logout_redirect_uri: import.meta.env.REACT_APP_URL,
    automaticSilentRenew: true,
    loadUserInfo: true,
    triggerAuthFlow: true,
    staleStateAge: 259200
};

export default configuration;
