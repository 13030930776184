import React from "react";
import {
    Alert,
    Card,
    CloseButton,
    Button,
    Col,
    Form,
    InputGroup,
    Modal,
    Row
} from "react-bootstrap";
import { CompanyContext } from "../CompanyContext";
import MultipleEmployeeSelection from "../documentCreation/MultipleEmployeeSelection";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useTranslation, { GetLocale } from "../../translation/Translations";
import { useOutOfOffice } from "../../api/Employee";
import { useQueryClient } from "react-query";
import OutOfOfficeDto from "../../dto/OutOfOffice";
import DocumentSubstituteDto from "../../dto/DocumentSubstitute";
import { format } from "date-fns";
import { useIsMobile } from "../../services/isMobile";
import httpService from "../../services/Http.service";

interface SetOutOfOfficeModalProps {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    outOfOffice: boolean;
    employeeId: string;
    language: string;
    refreshEmployee: () => void;
}

interface DateProps {
    from: Date;
    to: Date;
}

const SetOutOfOfficeModal = ({
    show,
    setShow,
    outOfOffice,
    employeeId,
    language,
    refreshEmployee
}: SetOutOfOfficeModalProps) => {
    const emptyState: OutOfOfficeDto = {
        employeeId,
        substituteId: "",
        dateFrom: "",
        dateTo: "",
        outOfOffice
    };
    const locale = GetLocale(language);

    const [useSubstitute, setUseSubstitute] = React.useState(false);
    const [substitute, setSubstitute] = React.useState<DocumentSubstituteDto[]>([]);
    const [outOfOfficeInfo, setOutOfOfficeInfo] = React.useState<OutOfOfficeDto>(emptyState);
    const [dates, setDates] = React.useState<DateProps>({} as DateProps);
    const [error, setError] = React.useState("");
    const company = React.useContext(CompanyContext);
    const t = useTranslation();
    const isMobile = useIsMobile();

    const handleOutOfOfficeData = (data: OutOfOfficeDto) => {
        const oofData = data;

        if (data.substituteId && data.substituteId !== "00000000-0000-0000-0000-000000000000") {
            setUseSubstitute(true);
            setSubstitute([
                {
                    id: 1,
                    employeeId: data.substituteId || "",
                    name: data.substituteName || "",
                    username: data.substituteUsername || "",
                    email: data.substituteEmail || ""
                }
            ]);
        }
        setOutOfOfficeInfo(oofData);
        setDates(() => ({ from: new Date(oofData.dateFrom), to: new Date(oofData.dateTo) }));
    };

    useOutOfOffice(employeeId, outOfOffice, handleOutOfOfficeData);

    const queryClient = useQueryClient();
    const handleOpen = () => {
        queryClient.invalidateQueries("out-of-office");
    };

    const handleClose = () => {
        handleDeleteSubstitute();
        setShow(false);
        setError("");
    };

    const handleSubstituteToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUseSubstitute(e.target.checked);
        handleDeleteSubstitute();
    };

    const handleDeleteSubstitute = () => {
        if (useSubstitute) {
            setSubstitute([]);
        }
    };

    const toggleOutOfOffice = () => {
        if (!useSubstitute) delete outOfOfficeInfo.substituteId;

        httpService
            .post(`outOfOffice`, outOfOfficeInfo)
            .then((res) => res.data)
            .then(() => {
                handleClose();
                refreshEmployee();
            })
            .catch((e) => {
                setError(e.message);
            });
    };

    React.useEffect(() => {
        if (substitute && substitute[0]) outOfOfficeInfo.substituteId = substitute[0].employeeId;
        else delete outOfOfficeInfo.substituteId;
    }, [substitute, outOfOfficeInfo]);

    const handleMobileFocus = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        if (isMobile) e.target.readOnly = true;
    };

    React.useEffect(() => {
        if (dates.from && dates.to) {
            setOutOfOfficeInfo((prevInfo) => ({
                ...prevInfo,
                dateFrom: format(dates.from, "yyyy-MM-dd"),
                dateTo: format(dates.to, "yyyy-MM-dd")
            }));
        }
    }, [dates]);

    React.useEffect(() => {
        setSubstitute([]);
        setUseSubstitute(false);
    }, [company.currentCompany]);

    const handleMobileBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        if (isMobile) e.target.readOnly = false;
    };
    return (
        <Modal show={show} onShow={() => handleOpen()} onHide={() => handleClose()}>
            <Modal.Header closeButton>
                <Modal.Title>{t("general.setOutOfOffice")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error !== "" ? <Alert variant="danger">{error}</Alert> : null}
                <Form>
                    <Form.Group as={Row}>
                        <Form.Label column md={10}>
                            {t("general.outOfOffice")}
                        </Form.Label>
                        <Col md={2}>
                            <div className="custom-control custom-switch">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="out-of-office"
                                    name="out-of-office"
                                    checked={outOfOfficeInfo.outOfOffice}
                                    onChange={(e) =>
                                        setOutOfOfficeInfo({
                                            ...outOfOfficeInfo,
                                            outOfOffice: e.target.checked
                                        })
                                    }
                                />
                                <label className="custom-control-label" htmlFor="out-of-office">
                                    {" "}
                                </label>
                            </div>
                        </Col>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{t("general.dateFrom")}</Form.Label>
                        <InputGroup className="datepicker">
                            <DatePicker
                                className="form-control append-left"
                                selected={dates.from}
                                onChange={(date) => {
                                    setDates((oldDates) => ({
                                        ...oldDates,
                                        from: date || new Date()
                                    }));
                                }}
                                dateFormat="yyyy-MM-dd"
                                locale={locale}
                                placeholderText={t("general.selectStartingDate")}
                                withPortal={
                                    isMobile || import.meta.env.REACT_APP_THEME === "terminal"
                                }
                                required
                                disabled={!outOfOfficeInfo.outOfOffice}
                                onFocus={handleMobileFocus}
                                onBlur={handleMobileBlur}
                            />
                            <InputGroup.Text className="datepicker-append decorative">
                                <FontAwesomeIcon icon={["far", "calendar-alt"]} />
                            </InputGroup.Text>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{t("general.dateTo")}</Form.Label>
                        <InputGroup className="datepicker">
                            <DatePicker
                                className="form-control append-left"
                                selected={dates.to}
                                onChange={(date) =>
                                    setDates((oldDates) => ({
                                        ...oldDates,
                                        to: date || new Date()
                                    }))
                                }
                                placeholderText={t("general.selectEndingDate")}
                                withPortal={
                                    isMobile || import.meta.env.REACT_APP_THEME === "terminal"
                                }
                                dateFormat="yyyy-MM-dd"
                                locale={locale}
                                required
                                disabled={!outOfOfficeInfo.outOfOffice}
                                onFocus={handleMobileFocus}
                                onBlur={handleMobileBlur}
                            />
                            <InputGroup.Text className="datepicker-append decorative">
                                <FontAwesomeIcon icon={["far", "calendar-alt"]} />
                            </InputGroup.Text>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column md={10}>
                            {t("general.specifySubstitute")}
                        </Form.Label>
                        <Col md={2}>
                            <div className="custom-control custom-switch">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="use-substitute"
                                    name="use-substitute"
                                    checked={useSubstitute}
                                    onChange={(e) => handleSubstituteToggle(e)}
                                    disabled={!outOfOfficeInfo.outOfOffice}
                                />
                                <label className="custom-control-label" htmlFor="use-substitute">
                                    {" "}
                                </label>
                            </div>
                        </Col>
                    </Form.Group>
                    {useSubstitute ? (
                        substitute.length === 0 ? (
                            <Form.Group>
                                <MultipleEmployeeSelection
                                    multiple={true}
                                    employees={substitute}
                                    setEmployees={setSubstitute}
                                    company={company.currentCompany}
                                    placeholder={t("general.searchForUser")}
                                    disabled={!outOfOfficeInfo.outOfOffice}
                                />
                            </Form.Group>
                        ) : (
                            <Card>
                                <Card.Body>
                                    {substitute[0].name}
                                    <CloseButton onClick={handleDeleteSubstitute} />
                                    <br />
                                    <small className="text-primary">
                                        <div>{substitute[0].email}</div>
                                    </small>
                                </Card.Body>
                            </Card>
                        )
                    ) : null}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => handleClose()}>
                    {t("general.cancel")}
                </Button>
                <Button variant="primary" onClick={() => toggleOutOfOffice()}>
                    {t("general.save")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SetOutOfOfficeModal;
