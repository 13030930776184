import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import EmployeeFunction from "../../enum/EmployeeFunction";
import { FunctionGuard } from "../../services/Function.service";
import useTranslation from "../../translation/Translations";

const ManagerSidebarItems = () => {
    const t = useTranslation();

    return (
        <FunctionGuard functions={[EmployeeFunction.Manager, EmployeeFunction.CEO]}>
            <span className="sidebar-separator">{t("sidebar.managerPortal")}</span>
            <LinkContainer to="/team">
                <Nav.Link>
                    <FontAwesomeIcon icon="users" fixedWidth className="sidebar-icon" />
                    <span>{t("sidebar.myTeam")}</span>
                </Nav.Link>
            </LinkContainer>

            <LinkContainer to="/schedule">
                <Nav.Link>
                    <FontAwesomeIcon icon="calendar" fixedWidth className="sidebar-icon" />
                    <span>{t("sidebar.calendar")}</span>
                </Nav.Link>
            </LinkContainer>
        </FunctionGuard>
    );
};

export default ManagerSidebarItems;
