import EmployeeDto from "../dto/Employee";
import HolidayRemainderDto from "../dto/HolidayRemainder";

export const placeholderEmployee: EmployeeDto = {
    language: "ENU",
    outOfOffice: false,
    id: "",
    userId: "",
    companyId: "",
    employeeNo: "",
    username: "",
    email: "",
    fullName: "",
    fullNameGenitive: "",
    fullNameVocative: "",
    fullNameAccusative: "",
    fullNameDative: "",
    middleName: "",
    orgUnit: "",
    orgUnitCode: "",
    position: "",
    positionCode: "",
    customManagerId: "",
    gender: 0,
    medicalExaminationExpiration: "",
    trialPeriodTo: "",
    sendEmailsTo: "",
    canManageInvoices: false,
    active: false,
    workPhone: ""
};

export const placeholderEmployeeRemainder: HolidayRemainderDto = {
    employeeId: "",
    updateDate: "",
    current: 0,
    currentRaw: 0,
    year: 0
};
