import React, { useContext, useState, useEffect, PropsWithChildren, createContext } from "react";
import ToastDto from "../dto/Toast";
import ColorType from "../enum/ColorType";
import { differenceInSeconds } from "date-fns";

const ToastsContext = createContext<ToastsContextProps>({});

interface ToastsContextProps {
    items?: ToastDto[];
    push?: (title: string, message: string, colorType?: ColorType) => void;
}

export const ToastsProvider = (props: PropsWithChildren<ToastsContextProps>) => {
    const [toasts, setToasts] = useState<ToastDto[]>([]);

    const push = (title: string, message: string, colorType?: ColorType) => {
        const id = "_" + Math.random().toString(36).substring(2, 9);
        setToasts([
            ...toasts,
            {
                id: id,
                title: title,
                message: message,
                created: new Date(),
                colorType: colorType ?? ColorType.Default
            }
        ]);
    };

    useEffect(() => {
        const currentDate = new Date();
        let newToasts = toasts;
        toasts.forEach((t) => {
            const diff = differenceInSeconds(currentDate, t.created);
            if (diff > 20) {
                newToasts = newToasts.filter((i) => i.id !== t.id);
            }
        });

        setToasts(newToasts);
    }, [toasts]);

    const value = {
        items: toasts,
        push: push
    };

    return <ToastsContext.Provider value={value}>{props.children}</ToastsContext.Provider>;
};

export const useToasts = () => {
    return useContext(ToastsContext);
};
